<template>
  <div class="personal-wrapper">
    <img class="personal_shade" src="../../assets/new_ui/personal_shade.png" alt="">
    <LeftMenu @handlerLogin="_clickLogin"/>
    <div class="personal-right-box">
      <router-view v-if="isLogin || path === '/personal-center/history-record'"></router-view>
      <div v-else class="no-data-box card-wrapper">
        <img src="../../assets/new_ui/no_login_img.png" alt="" class="img-no-data">
        <div class="no-data-text">
          <p class="no-data-title">你还没有登录哦~</p>
          <p class="no-data-title" style="marginTop: 10px">请登录后再进行相关操作</p>
          <div class="login-btn-box">
            <div class="login-btn" @click="_clickLogin">登录/注册</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 登录 注册 找回密码 -->
    <LoginRegisterPwdDialog
      :visible="loginDialogVisible"
      component="login"
      @handleClick="_closeLoginDialog"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";
import LeftMenu from "./left-menu";
import LoginRegisterPwdDialog from "components/login-register-pwd-dialog";
export default {
  components: {
    LeftMenu,
    LoginRegisterPwdDialog
  },
  data() {
    return {
      loginDialogVisible: false,
      path: ""
    };
  },
  computed: {
    ...mapState(["accountInfo", "isLogin"])
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        this.path = to.path;
      }
    }
  },
  methods: {
    _clickLogin() {
      this.loginDialogVisible = false;
      setTimeout(() => {
        this.loginDialogVisible = true;
      }, 200);
    },

    /**
     * 关闭登录
     * **/
    _closeLoginDialog(type) {
      this.loginDialogVisible = false;
    }
  },
  created() {}
};
</script>
<style lang="less" scoped>
.page-wrapper {
  width: 1300px;
  padding-top: 44px;
  // min-height: 700px !important;
  // width: calc(135vh) !important;
}
</style>
<style lang="less">
@color-main: #03b2b2;
.personal-wrapper {
  width: 70vw;
  height: 74vh;
  margin: auto;
  position: relative;
  z-index: 15;
  margin-top: 4vh;
  .page-wrapper {
    // width: 1300px !important;
  }
  .personal-right-box {
    // width: 10.22rem;
    // height: 7.15rem;
    width: 50vw;
    height: 100%;
    display: inline-block;
    color: #448816;
    .login-btn-box {
      margin-top: 0.15rem;
    }
    .login-btn {
      width: 1.35rem;
      height: 0.3rem;
      line-height: 0.3rem;
      font-weight: normal;
      background: #67b035;
      color: #fff;
      border-radius: 10px;
      font-size: 0.16rem;
    }
  }
  .no-data-box {
    display: flex;
    justify-content: center;
    align-items: center;
    .img-no-data {
      width: 2rem;
      height: 2rem;
      margin-top: 0;
      margin-right: 0.5rem;
    }
    .no-data-text {
      font-size: 0.16rem;
      margin-top: 0.1rem;
    }
  }
}
.personal_shade {
  width: 14.84rem;
  position: absolute;
  left: -0.22rem;
  bottom: -0.45rem;
}
</style>