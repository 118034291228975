/** 
 * @Desc: 个人中心--修改密码
 */
<template>
  <div class="change-pwd-wrapper card-wrapper">
    <!-- <img src="../../assets/personal_ip.png" alt="" class="img-bottom-bg"> -->
    <img src="../../assets/new_ui/personal_ip.png" alt="" class="img-bottom-bg">
    <img
      src="../../assets/new_ui/personal_ip_left.png"
      alt=""
      class="img-bottom-bg"
      style="left: -0.5rem"
    >
    <el-form
      label-position="right"
      :model="infoForm"
      :rules="ruleForm"
      ref="ruleForm"
      label-width="1.2rem"
      class="self-el-form"
    >
      <!-- 初始密码 -->
      <el-form-item label="当前密码：" prop="oldPassword">
        <el-input type="password" v-show="false" auto-complete="false"></el-input>
        <el-input
          class="mvr-el-input"
          type="password"
          v-model.trim="infoForm.oldPassword"
          :maxlength="14"
          auto-complete="false"
          placeholder="请输入当前密码"
        ></el-input>
        <el-input type="password" v-show="false" auto-complete="false"></el-input>
      </el-form-item>
      <!-- 新密码 -->
      <el-form-item label="新密码：" prop="newPassword">
        <el-input
          class="mvr-el-input"
          type="password"
          v-model.trim="infoForm.newPassword"
          :maxlength="14"
          auto-complete="false"
          placeholder="密码长度6-14位，字母区分大小写"
        ></el-input>
      </el-form-item>
      <!-- 确认密码 -->
      <el-form-item label="确认密码：" prop="checkPassword">
        <el-input type="password" v-show="false" auto-complete="false"></el-input>
        <el-input
          class="mvr-el-input"
          type="password"
          v-model.trim="infoForm.checkPassword"
          :maxlength="14"
          auto-complete="false"
          placeholder="请再次输入新密码"
        ></el-input>
        <el-input type="password" v-show="false" auto-complete="false"></el-input>
        <p v-if="false" class="pwd-explain">*密码长度6-14位，字母区分大小写</p>
      </el-form-item>
    </el-form>
    <div class="bottom-btns">
      <el-button type="primary" size="small" :loading="okLoading" @click="!okLoading && _save()">保存</el-button>
    </div>
  </div>
</template>
<script>
import { localStorageUtil, debounce } from "utils/util";
import { reqUpdatePassword } from "api/personal-center";
export default {
  data() {
    const oldPasswordR = (rule, value, callback) => {
      if (this.okLoading && !value) {
        return callback(new Error("请输入当前密码"));
      } else if (this.oldPwdError) {
        this.oldPwdError = false;
        return callback(new Error("当前密码错误"));
      }
      return callback();
    };
    const newPasswordR = (rule, value, callback) => {
      let reg = /^[a-zA-Z0-9]{6,14}$/g;
      if (this.okLoading && !value) {
        return callback(new Error("请输入新密码"));
      } else if (!value) {
        return callback();
      } else if (value.length > 14 || value.length < 6) {
        return callback(new Error("密码长度6-14位，字母区分大小写"));
      } else if (!reg.test(value)) {
        return callback(new Error("密码仅支持英文和数字"));
      } else if (this.infoForm.oldPassword === value) {
        return callback(new Error("新密码不可与当前密码相同"));
      }
      callback();
    };

    const checkPasswordR = (rule, value, callback) => {
      if (this.okLoading && !value) {
        callback(new Error("请再次输入新密码"));
      } else if (value && value !== this.infoForm.newPassword) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };

    return {
      infoForm: {
        oldPassword: "",
        newPassword: "",
        checkPassword: ""
      },
      ruleForm: {
        oldPassword: [{ trigger: "blur", max: 20, validator: oldPasswordR }],
        newPassword: [
          { trigger: "blur", min: 6, max: 20, validator: newPasswordR }
        ],
        checkPassword: [{ trigger: "blur", validator: checkPasswordR }]
      },
      oldPwdError: false,
      okLoading: false
    };
  },
  methods: {
    /**
     * 保存
     * **/
    _save: debounce(function() {
      this.okLoading = true;
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this._updateInfo();
        } else {
          this.okLoading = false;
        }
      });
    }),
    /**
     * 更新基本资料
     * **/
    _updateInfo() {
      let obj = {
        oldPassword: this.infoForm.oldPassword,
        newPassword: this.infoForm.newPassword
      };
      return reqUpdatePassword(obj)
        .then(res => {
          this.okLoading = false;
          if (res.code === 0) {
            this.$message({
              type: "success",
              message: "密码已更新",
              duration: 2000
            });
            this.infoForm = {
              oldPassword: "",
              newPassword: "",
              checkPassword: ""
            };
          } else if (res.code === 12003) {
            this.oldPwdError = true;
            this.$refs.ruleForm.validateField("oldPassword");
          } else {
            this.$message({
              type: "error",
              message: "密码更新失败",
              duration: 2000
            });
          }
        })
        .catch(err => {
          this.okLoading = false;
          console.log("密码更新失败", err);
        });
    }
  },
  created() {}
};
</script>
<style lang="less">
.change-pwd-wrapper {
  .self-el-form {
    // padding-top: 1.32rem;
    // padding-left: 2.54rem;
    // padding-left: 2.1rem;

    padding-top: 1.3rem;
    padding-left: 2.45rem;
    padding-right: 2.63rem;

    .el-form-item__label {
      font-size: 0.14rem;
      height: 0.4rem;
      line-height: 0.4rem;
    }
    .el-input__icon {
      font-size: 0.14rem;
      height: 0.4rem;
      line-height: 0.4rem;
      margin-right: 0.05rem;
    }
    .el-form-item {
      margin-top: 0.3rem;

      .el-form-item__content {
        line-height: 0 !important;

        .el-input {
          width: 3.58rem;
          height: 0.44rem;
          font-size: 0.14rem;
          .el-input__inner {
            height: 0.4rem;
          }
        }
      }
    }
  }
  .bottom-btns {
    width: 100%;
    height: 0.44rem;

    .el-button {
      width: 3.38rem;
      height: 0.44rem;
      margin-left: 3.61rem;
      margin-right: 2.87rem;
      font-size: 0.16rem;
      line-height: 0.44rem;
    }
  }
}
</style>