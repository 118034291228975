/** 
 * @Desc: 个人中心--绘画记录
 */
<template>
  <div class="my-baby-wrapper card-wrapper">
    <div v-if="picDatas.length <=0" class="no-data-box">
        <img src="../../assets/new_ui/no_data_img.png" alt="" class="img-no-data">
        <div class="no-data-text">
          <p class="no-data-title">您还没有绘画记录哦~</p>
          <p class="no-data-title" style="marginTop: 10px">请开始您的创作吧</p>
        </div>
      </div>
    <div v-if="picDatas.length > 0" class="painting-record-container" @scroll="scrollLoadMore($event)">
      <div class="painting-record-item" v-for="(item, index) in picDatas" :key="index" @click="checkImage(item)" :ref="'scrollDom' + item.taskId" :class="(item.status == 3 || item.status == 0) ? 'crudeBorder' : 'fineBorder'">
        <el-image class="my_img" :src="item.status == 1 ? item.thumbnailUrl : ''" alt="" fit="cover" :preview-src-list="item.imageUrls.map(element=> element.paintingRecordUrl)">
          <div slot="error" class="image-slot">
                <!-- <div v-if="item.status == 1" class="paintingFailText paintingFailText1"></div> -->
                <div v-if="item.status == 3" class="paintingFailText paintingFailText3"></div>
                <!-- <div v-if="item.status == 0" class="paintingFailText paintingFailText0"></div> -->
              </div>
        </el-image>
      </div>
    </div>

  </div>
</template>
<script>
import { reqPainting } from '@/api/resource.js';
import { SetVideoStatistics } from "api/common";
import { downPic } from '@/api/resource.js'
export default {
  data() {
    return {
      pageSwitch: true,
      pageNum: 0, //当前请求页码
      pageSize: 12, //请求条数
      totalCount: 5,
      pageSource: 2, //请求页来源 2：个人中心
      picDatas: [],
      isInView: false,
      timer: null,
      objImageData:{}
    }
  },
  methods: {
    checkImage(objImageData){
      let wrapper = document.getElementsByClassName(
          'el-image-viewer__actions__inner'
        )
        let downImg = document.createElement('i')
        downImg.setAttribute('class', 'el-icon-download')
        wrapper[0].appendChild(downImg)
        if (wrapper.length > 0) {
          this.wrapperElem = wrapper[0]
          this.cusClickHandler()
        }
        this.objImageData = objImageData
    },   
        /**
     * 点击下载图片
     */
     cusClickHandler() {
      this.wrapperElem.addEventListener('click', this.hideCusBtn)
    },
    async hideCusBtn(e) {
      let className = e.target.className
      if (className === 'el-icon-download') {
        let imgUrl = document.getElementsByClassName(
          'el-image-viewer__canvas'
        )[0].children[0].src
        console.log(imgUrl);//图片地址
        let taskId = this.objImageData.taskId;
        let _taskId = taskId.indexOf("_") != -1 ? taskId.substr(0, taskId.indexOf("_")) : taskId;

        // this.objImageData.arrImageUrls = arrImageUrls
        SetVideoStatistics({
            resourceId: _taskId,
            level: 1,
            subject: 1,
            type: 'download'
          });

        let imgData = this.objImageData.imageUrls.find(item =>
          item.paintingRecordUrl == imgUrl
        )
        let paintingRecordId  = imgData.paintingRecordId
        let paintingRecordUrl = imgData.paintingRecordUrl
        let reg2 = /([^/]+)$/;
        let fileName = paintingRecordUrl.match(reg2)[1];
        fileName = fileName || 'download-image'
        let query = {
          paintingRecordId,
          taskId,
          fileName
        }
        let res = await downPic(query)
        // console.log(res);
        // let reg2 = /([^/]+)$/;
        // let imgName = imgUrl.match(reg2)[1];
        // imgName = imgName || 'download-image'
        // this.downloadImage(imgUrl)

      }
    },
    downloadImage(imgUrl) {
      let tmpArr = imgUrl.split('/')
      let fileName = tmpArr[tmpArr.length - 1]
      window.URL = window.URL || window.webkitURL
      let xhr = new XMLHttpRequest()
      xhr.open('get', imgUrl, true)
      xhr.responseType = 'blob'
      xhr.onload = function () {
        if (this.status == 200) {
          //得到一个blob对象
          let blob = this.response
          let fileUrl = window.URL.createObjectURL(blob)
          if (navigator.msSaveBlob) {
            return navigator.msSaveBlob(x.response, fileName);
          } else {
            let a = document.createElement('a');
            (document.body || document.documentElement).appendChild(a)
            a.href = fileUrl
            if ('download' in a) {
              a.download = fileName
            } else {
              a.setAttribute('download', fileName)
            }
            a.target = '_self'
            a.click()
            a.remove()
          }
        }}
      xhr.send()
    },

    //获取历史绘画数据
    async getPainting() {
      if (this.pageSwitch) {
        this.pageNum++;
        let { pageNum, pageSize, pageSource } = this
        let query = {
          pageNum,
          pageSize,
          pageSource
        }
        let res = await reqPainting(query)
        if (res.data) {
          this.totalCount = res.data.totalCount;
          let picArr = res.data.datas
          this.picDatas = (this.pageNum > 1) ? [...this.picDatas, ...picArr] : picArr

          if (res.data.datas.length < this.pageSize) {
            this.pageSwitch = false;
            this.pageNum = this.pageNum - 1;
          }
        } else {
          this.pageSwitch = false;
          this.pageNum = this.pageNum - 1;
        }
      }
    },
    /**
     * 滚动加载更多
     */
    scrollLoadMore(event) {
      const scrollTop = event.target.scrollTop;
      const delta = scrollTop - this.lastScrollTop;
      this.lastScrollTop = scrollTop;
      if (delta > 0) {
        // 向下滚动
        // console.log('--scrolling downwards--');
        let taskId = this.picDatas[this.picDatas.length - 1].taskId
        let objElement = this.$refs['scrollDom' + taskId][0]
        // let objElement = document.getElementById('#scrollDom' + taskId);
        // console.log(objElement);
        //获取元素是否在可视区域
        let rect = objElement.getBoundingClientRect();
        // //判断位置
        let isInView = rect.top + rect.height / 2 <=
          (window.innerHeight || document.documentElement.clientHeight) && rect.bottom > 0;
        // console.log(isInView);
        if (isInView) {
          if (this.timer !== null) clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            this.getPainting()
          }, 200)
        } else {
          this.isInView = false
        }
      }
    }
  },
  mounted() {
    window.cl = this.cl,
      this.getPainting()
  }
};
</script>
<style lang="less">
/**
  调试全屏的样式可选
*/
// .el-image-viewer__wrapper{
// .el-image-viewer__canvas{
//   align-items: inherit;
// }
// }
.my-baby-wrapper {
  width: 100%;
  height: 100%;
  padding: 2vw;

  .no-data-box{
    width: 100%;
    height: 100%;
    .img-no-data{
      height: auto;
    }
  }

  .painting-record-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    border-radius: 2vw;
    padding-left: .5vw;

    .crudeBorder{
      border: 3px solid #ade787;
    }

    .fineBorder{
      border: 1px solid #ade787;
    }

    .painting-record-item {
      width: 12.5vw;
      height: 12.5vw;
      margin-bottom: 2vw;
      overflow: hidden;
      border-radius: 30px;
      padding: 0;
      // background-color: red;
      .my_img {
        // border-radius: 40px;
        width: 100%;
        height: 100%;
        transform: scale(1);
        transition: transform .4s ease-in-out;
        
      .paintingFailText {
          // width: calc(12.3vw - 1px);
          width: 12.2vw;
          height: 12.5vw;
          position: relative;
        }

        .paintingFailText3 {
          background: url('../../assets/ui_ai-fe/绘制失败.jpg') no-repeat;
          background-size: 50%;
          background-position: center 30%;

          &::after {
            display: inline-block;
            width: 100%;
            text-align: center;
            content: '绘图失败';
            position: absolute;
            bottom: 3vw;
            left: 0;
            font-size: 1vw;
            color: #448816;
          }
        }
        // .paintingFailText1 {
        //   background: url('../../assets/ui_ai-fe/绘制失败.jpg') no-repeat;
        //   background-size: 50%;
        //   background-position: center 30%;

        //   &::after {
        //     display: inline-block;
        //     width: 100%;
        //     text-align: center;
        //     content: '加载图片失败';
        //     position: absolute;
        //     bottom: -3vw;
        //     left: 0;
        //   }
        // }

        // .paintingFailText0 {
        //   background: url('../../assets/ui_ai-fe/绘制中.jpg') no-repeat;
        //   background-size: 50%;
        //   background-position: center 30%;

        //   &::after {
        //     display: inline-block;
        //     width: 100%;
        //     text-align: center;
        //     content: '图片生成中';
        //     position: absolute;
        //     bottom: -3vw;
        //     left: 0;
        //   }
        // }
      }
      .my_img:hover {
        transform: scale(1.15);
      }
    }

    .painting-record-item:nth-of-type(3n+2) {
      margin-right: 2vw;
      margin-left: 1vw;
    }

    .painting-record-item:nth-of-type(3n+1) {
      margin-right: 2vw;
    }

  }

  .painting-record-container::-webkit-scrollbar {
    display: block;
    width: 5px;
  }

  .painting-record-container::-webkit-scrollbar-thumb {
    display: block;
    border-radius: 8px;
    background-color: #448816;
  }
  // .add-baby {
  //   position: absolute;
  //   z-index: 10;
  //   cursor: pointer;

  //   .icontianjia {
  //     color: #ffffff;
  //     position: absolute;
  //     top: 50%;
  //     left: 50%;
  //     transform: translate(-30%, -50%);
  //   }

  //   &.add-baby-center {
  //     position: relative;
  //     text-align: center;
  //     background: #fff;
  //     width: 258px;
  //     height: 328px;
  //     border: 5px solid #e3facd;
  //     top: 50%;
  //     left: 50%;
  //     // transform: translate(-50%, -50%);
  //     transform: translate(-83%, -56%);
  //     border-radius: 30px;

  //     .add-baby-inner {
  //       width: 84px;
  //       height: 84px;
  //       // background: #ffce75;
  //       background: url("../../assets/new_ui/add_baby.png") no-repeat;
  //       // border-radius: 12px;
  //       // border: 4px solid #ffffff;
  //       position: relative;
  //       top: 50%;
  //       left: 50%;
  //       transform: translate(-51%, -70%);

  //       // .icontianjia {
  //       //   font-size: 30px;
  //       // }

  //       &:hover {
  //         // width: 92px;
  //         // height: 98px;
  //         // background: #f3bb55;
  //         // opacity: 0.8;
  //         background: url("../../assets/new_ui/add_baby_hover.png") no-repeat;
  //         background-size: 100% 100%;
  //         // position: relative;
  //         // top: 50%;
  //         // left: 50%;
  //         // transform: translate(-48%, -65%);
  //       }
  //     }

  //     .p-add {
  //       height: 16px;
  //       font-size: 16px;
  //       line-height: 16px;
  //       color: #46851b;
  //       margin-top: 120px;
  //     }
  //   }

  //   &.add-baby-right-top {
  //     width: 0.7rem;
  //     height: 0.7rem;
  //     // background: #ffce75;
  //     background: url("../../assets/new_ui/add_baby.png") no-repeat;
  //     background-size: 100% 100%;

  //     // border-radius: 12px;
  //     // border: 4px solid #ffffff;
  //     bottom: 0.75rem;
  //     right: 0.66rem;

  //     &:hover {
  //       // background: #f3bb55;
  //       // opacity: 0.8;
  //       background: url("../../assets/new_ui/add_baby_hover.png") no-repeat;
  //       background-size: 100% 100%;
  //     }
  //   }
  // }

  // ::-webkit-scrollbar {
  //   display: block !important;
  // }

  // .baby-list {
  //   width: 100%;
  //   height: 100%;
  //   overflow: auto;
  //   // padding: 0.82rem 2.14rem;
  //   // padding-right: 1rem;
  //   padding-top: 0.3rem;
  //   padding-left: 0.1rem;
  //   margin-bottom: 0;
  //   padding-right: 0;

  //   // overflow: hidden;
  //   &:hover {
  //     // overflow: scroll;
  //   }

  //   &.baby-list-1 {
  //     // padding-top: 1.2rem;
  //     // padding-left: 2.3rem;
  //     padding-top: 1.28rem;
  //     padding-left: 1.65rem;
  //   }

  //   &.baby-list-2 {
  //     // padding-top: 100px;
  //     // padding-left: 235px;
  //     // padding: 50px;
  //     // margin: auto;
  //     // text-align: center;
  //     // padding: 100px;
  //     // padding: 1rem 2.1rem 0 2.1rem;
  //     margin-top: 0.1rem;

  //     .baby-item {
  //       // margin-right: 38px;
  //       margin-right: 0.5rem;
  //     }
  //   }

  //   &.baby-list-n {
  //     // margin-top: 40px;
  //     // padding-top: 40px;
  //     // padding-left: 90px;
  //     // margin-bottom: 40px;
  //     height: calc(100% - 26px);

  //     .baby-item {
  //       margin-right: 0.5rem;
  //     }
  //   }

  //   .baby-item {
  //     // width: 218px;
  //     // height: 216px;
  //     // padding: 80px 20px 0;
  //     padding: 1.31rem 0px 0;
  //     // background: #fff9ee;
  //     border-radius: 30px;
  //     position: relative;
  //     display: inline-block;
  //     margin-bottom: 0.58rem;

  //     &.baby-item-1 {

  //       .baby-name,
  //       .baby-org {
  //         color: #54a3b4;
  //       }

  //       .baby-age,
  //       .baby-class,
  //       .baby-edit {
  //         color: #7abcc9;
  //       }
  //     }

  //     &.baby-item-2 {

  //       .baby-name,
  //       .baby-org {
  //         color: #ff4940;
  //       }

  //       .baby-age,
  //       .baby-class,
  //       .baby-edit {
  //         color: #f98f89;
  //       }
  //     }

  //     &:nth-of-type(2n + 2) {
  //       margin-right: 0;
  //     }

  //     .baby-item-box {
  //       // width: 190px;
  //       // height: 140px;
  //       width: 2.58rem;
  //       height: 1.74rem;
  //       text-align: center;
  //       background: url("../../assets/new_ui/baby_box.png") no-repeat 100% 100%;
  //       position: relative;
  //       background-size: 100%;
  //       padding: 0 0.2rem;
  //       padding-top: 28px;
  //       overflow: hidden;
  //     }

  //     .img-top-bg {
  //       width: 2.358rem;
  //       height: 2.19rem;
  //       border-radius: 20px 20px 0 0;
  //       position: absolute;
  //       top: -23px;
  //       left: 8px;
  //       left: 0.11rem;
  //     }

  //     .baby-edit {
  //       width: 0.62rem;
  //       height: 0.64rem;
  //       display: none;
  //       position: absolute;
  //       right: 0.03rem;
  //       bottom: 0.06rem;

  //       // right: 3px;
  //       // bottom: 6px;
  //       font-size: 20px;
  //       z-index: 10;

  //       cursor: pointer;

  //       &:hover {
  //         opacity: 0.8;
  //       }
  //     }

  //     .baby-name {
  //       // font-size: 16px;
  //       font-size: 18px;
  //       height: 20px;
  //       // line-height: 16px;
  //       margin-top: 10px;

  //       font-size: 0.18rem;
  //       height: 0.2rem;
  //       margin-top: 0.1rem;
  //     }

  //     .baby-age {
  //       // font-size: 14px;
  //       font-size: 16px;
  //       height: 14px;
  //       // line-height: 14px;
  //       margin-top: 0.1rem;

  //       font-size: 0.16rem;
  //       height: 0.14rem;
  //       margin-top: 0.1rem;
  //     }

  //     .baby-org {
  //       font-size: 16px;
  //       height: 16px;
  //       // line-height: 16px;
  //       margin-top: 0.2rem;

  //       font-size: 0.16rem;
  //       height: 0.16rem;
  //       margin-top: 0.1rem;
  //     }

  //     .baby-class {
  //       font-size: 0.16rem;
  //       height: 0.2rem;
  //       margin-top: 0.1rem;
  //       position: relative;
  //       z-index: 5;
  //     }

  //     &:hover {
  //       .baby-edit {
  //         display: inline-block;
  //       }
  //     }
  //   }
  // }
}

// .add-baby-dialog {
//   .el-form {
//     .form-item-studentBirthday {
//       .el-date-editor {
//         width: 280px;

//         .el-input__prefix {
//           display: none;
//         }

//         .el-input__suffix {
//           display: inline-block;
//           width: 20px;
//           height: 18px;
//           background-image: url("../../assets/new_ui/icon-calendar.png");
//           background-repeat: no-repeat;
//           background-size: 20px 18px;
//           position: absolute;
//           right: 0.12rem;
//           top: 12px;

//           .el-input__suffix-inner {
//             display: none;
//           }
//         }
//       }
//     }
//   }

//   .bottom-btns {
//     padding-left: 100px;
//     margin-bottom: 20px;

//     .el-button {
//       width: 280px;
//     }
//   }

//   .self-el-form {
//     margin-left: 5px;
//   }
// }
</style>